import FlowGalleryOptions from 'flow-gallery-options';

export default class FlowGallerySettings implements FlowGalleryOptions {
    public selector = '.flow-gallery';
    public maxHeight = 400;
    public gapWidth = 10;
    public percent = false;
    public fillLastLine = false;
    public enlarge = false;
    public align = 'left';

    public applyOptions(options?: FlowGalleryOptions): void {
        Object.assign(this, options);
    }
}
