import FlowGallerySettings from 'flow-gallery-settings';

export default class FlowGalleryInstance {
    private maxWidth: number = 0;
    private maxHeight: number = 0;
    private gapWidth: number = 0;
    private rows: number = 0;
    private stoppedAt: number = 0;

    constructor(private readonly settings: FlowGallerySettings, private readonly gallery: HTMLElement) {
        this.fullUpdate = this.fullUpdate.bind(this);
        this.update = this.update.bind(this);
    }

    public fullUpdate(): void {
        this.rows = 0;
        this.stoppedAt = 0;

        this.update();
    }

    public async update(): Promise<void> {
        const items = this.gallery.children;

        this.maxWidth = Math.floor(this.gallery.clientWidth);
        this.maxHeight = this.getMaxHeight(this.maxWidth);
        this.gapWidth = this.getGapWidth(this.maxWidth);

        this.gallery.style.justifyContent = this.getAlignment(this.settings.align);
        this.gallery.classList.add('flow-gallery-grid');

        let rowWidth = 0;
        let rowHeight = this.maxHeight;
        let rowItems: HTMLElement[] = [];

        for (let i = this.stoppedAt; i < items.length; i++) {
            const item = items[i] as HTMLElement;

            const ratio = await this.getRatio(item);
            const imageHeight = await this.getImageHeight(item);

            if (!this.settings.enlarge && imageHeight < rowHeight) {
                const factor = imageHeight / rowHeight;
                rowHeight = imageHeight;
                rowWidth = rowWidth * factor;
            }

            const itemWidth = ratio * rowHeight;

            rowWidth += itemWidth;
            rowItems.push(item);

            if (rowWidth + this.getTotalGapWidth(rowItems) >= this.maxWidth) {
                this.createRow(rowItems, rowWidth, rowHeight);
                rowWidth = 0;
                rowHeight = this.maxHeight;
                rowItems = [];
                this.stoppedAt = i + 1;
            }
        }

        this.createRow(rowItems, rowWidth, rowHeight);

        rowWidth = 0;
        rowHeight = this.maxHeight;
        rowItems = [];
    }

    private async createRow(rowItems: HTMLElement[], rowWidth: number, rowHeight: number): Promise<void> {
        const totalGapWidth = this.getTotalGapWidth(rowItems);
        const factor = (this.maxWidth - totalGapWidth) / rowWidth;

        rowHeight = Math.floor(rowHeight * factor);

        if (rowHeight > this.maxHeight && !this.settings.fillLastLine) {
            rowHeight = this.maxHeight;
        }

        for (let i = 0; i < rowItems.length; i++) {
            const item = rowItems[i];

            const ratio = await this.getRatio(item);
            const width = Math.floor(ratio * rowHeight);

            item.style.width = width.toString() + 'px';
            item.style.height = rowHeight.toString() + 'px';

            item.style.marginLeft = i > 0 ? this.gapWidth.toString() + 'px' : '0';
            item.style.marginTop = this.rows > 0 ? this.gapWidth.toString() + 'px' : '0';
        }

        this.rows++;
    }

    private async getRatio(image: Element): Promise<number> {
        const referenceImage = await this.getReferenceImage(image);
        return referenceImage.naturalWidth / referenceImage.naturalHeight;
    }

    private async getImageHeight(image: Element): Promise<number> {
        const referenceImage = await this.getReferenceImage(image);
        return referenceImage.naturalHeight;
    }

    private async getReferenceImage(item: Element): Promise<HTMLImageElement> {
        if (item instanceof HTMLImageElement) {
            await this.loadImage(item);
            return item;
        }

        const image = item.querySelector('img');

        if (image) {
            await this.loadImage(image);
            return image;
        }

        throw new Error('No reference image available.');
    }

    private loadImage(image: HTMLImageElement): Promise<void> {
        return new Promise(resolve => {
            if (image.complete) {
                resolve();
            } else {
                image.addEventListener('load', () => {
                    resolve();
                });
            }
        });
    }

    private getGapWidth(maxWidth: number): number {
        return this.settings.percent ? (maxWidth / 100) * this.settings.gapWidth : this.settings.gapWidth;
    }

    private getTotalGapWidth(rowItems: HTMLElement[]): number {
        return (rowItems.length - 1) * this.gapWidth;
    }

    private getMaxHeight(maxWidth: number): number {
        return this.settings.percent ? (maxWidth / 100) * this.settings.maxHeight : this.settings.maxHeight;
    }

    private getAlignment(alignment: string): string {
        switch (alignment) {
            case 'center':
                return 'center';
            case 'right':
                return 'flex-end';
            default:
                return 'flex-start';
        }
    }
}
